import React, { Component } from "react";
import { Container, Row, Col, Image, Figure, Modal } from "react-bootstrap";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Navigation from "./components/navigation";
import Products from "./components/productCarousel";

class App extends Component {
  state = { showModal: false, selectedImage: null };

  hideModal = () => {
    this.setState({ showModal: false, selectedImage: null });
  };

  showModal = (imagePath) => {
    const selectedImage = require(`.${imagePath}`);
    console.log(selectedImage);
    this.setState({ showModal: true, selectedImage: selectedImage });
  };

  render({ selectedImage, showModal } = this.state) {
    return (
      <React.Fragment>
        <div className="video-container">
          <div className="color-overlay" />
          <video autoPlay muted>
            <source
              src={require("./images/background6.mp4")}
              type="video/mp4"
            />
          </video>
        </div>

        <Container fluid id="home">
          <Navigation />

          <Row className="content-row-padding">
            <Col md={1} />
            <Col xs={12} md={10}>
              <div className="headline-container">
                <div className="headline">
                  <h1>Klenoty Zodiak</h1>
                  <h2>Specialista na zakázkovou výrobu šperků</h2>
                  <p>
                    Vám nabízí ručně vyrobené zlaté šperky, jako krásný
                    Valentýnský dárek, který neztrací na své hodnotě.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={1} />
          </Row>

          <Row className="content-row-padding" id="nabidka">
            <Col md={1} />
            <Col xs={12} md={10}>
              <Products showModal={this.showModal} />
            </Col>
            <Col md={1} />
          </Row>

          <Row className="content-row-padding" id="cisteni">
            <Col md={1} />
            <Col xs={12} md={10}>
              <div className="sectionWrapper">
                <h2>Čištění stříbra</h2>
                <p>
                  Provádíme renovaci a opravy starých nádob, příborů, pohárů,
                  šperků a dalších předmětů ze stříbra i alpaky
                </p>
                <Container fluid style={{ marginTop: "30px" }}>
                  <Row>
                    <Col xs={12} md={4}>
                      <Figure>
                        <Figure.Image
                          style={{ cursor: "pointer" }}
                          width={200}
                          onClick={() =>
                            this.showModal(`/images/cisteni/1.jpg`)
                          }
                          src={require(`./images/cisteni/1.jpg`)}
                          thumbnail
                        />
                      </Figure>
                    </Col>
                    <Col xs={12} md={4}>
                      <Figure>
                        <Figure.Image
                          style={{ cursor: "pointer" }}
                          width={200}
                          onClick={() =>
                            this.showModal(`/images/cisteni/2.jpg`)
                          }
                          src={require(`./images/cisteni/2.jpg`)}
                          thumbnail
                        />
                      </Figure>
                    </Col>
                    <Col xs={12} md={4}>
                      <Figure>
                        <Figure.Image
                          style={{ cursor: "pointer" }}
                          width={200}
                          onClick={() =>
                            this.showModal(`/images/cisteni/3.jpg`)
                          }
                          src={require(`./images/cisteni/3.jpg`)}
                          thumbnail
                        />
                      </Figure>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
            <Col md={1} />
          </Row>
          <Row className="content-row-padding" id="zakazky">
            <Col md={1} />
            <Col xs={12} md={10}>
              <div className="sectionWrapper">
                <h2>Zakázková výroba šperků</h2>
                <p>
                  Sháníte šperk, který by přesně vyhovoval Vašim představám?
                </p>
                <p>
                  Nevybrali jste si v řádném obchodě? Slyšíte často odpověď:
                  "Tenhle šperk neseženete"?
                </p>
                <p>
                  Není třeba zoufat, náš zlatník dokáže vytvořit téměř jakýkoliv
                  motiv.
                </p>
                <p>
                  Přívěšek ve tvaru Vašeho oblíbeného zvířátka či předmětu. S
                  jakýmkoliv motivem, znakem či textem. Téměř nic není nemožné,
                  jakykoliv kov či kámen - zlato či brilianty.
                </p>
              </div>
            </Col>
            <Col md={1} />
          </Row>
          <Row className="content-row-padding" id="opravy">
            <Col md={1} />
            <Col xs={12} md={10}>
              <div className="sectionWrapper">
                <h2>Opravy</h2>
                <p>Roztrhl se Vám oblíbený řetízek nebo náušnice?</p>
                <p> Je Vám Váš prstýnek velký nebo malý?</p>
                <p>Nebo Vám snad vypadly diamanty?</p>
                <p>
                  Náš profesionální zlatník Vaše šperky uvede do původního
                  stavu.
                </p>
              </div>
            </Col>
            <Col md={1} />
          </Row>
          <Row className="content-row-padding" id="kontakty">
            <Col md={1} />
            <Col xs={12} md={10}>
              <div className="sectionWrapper">
                <h2>Klenoty Zodiak</h2>
                <p>
                  <span itemProp="address">
                    Štefánikova 13/43 - Praha 5, 150 00
                  </span>
                  <br />
                  <span itemProp="telephone">+420 776 686 885</span>
                  <br />
                  <span>
                    <a href="mailto:prodej@klenotyzodiak.cz" itemProp="email">
                      prodej@klenotyzodiak.cz
                    </a>
                  </span>
                  <br />
                  <br />
                  Otevírací doba
                  <br />
                  Po-Pá: 9 - 18:00
                </p>
              </div>
            </Col>
            <Col md={1} />
          </Row>
          <Row className="content-row-padding">
            <Col md={1} />
            <Col xs={12} lg={4}>
              <div className="sectionWrapper">
                <img
                  loading="lazy"
                  src={require("./images/shop.jpg")}
                  rounded
                  style={{ width: "100%" }}
                  alt="shop"
                />
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="sectionWrapper">
                <iframe
                  className="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.526355614125!2d14.401660315534246!3d50.07643137942551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94f938dd9c6d%3A0xd177c7b695b1b732!2sKlenoty+Zodiak!5e0!3m2!1scs!2scz!4v1469997175886"
                  width="100%"
                  height="400px"
                  style={{ border: "0" }}
                  title="Map"
                  allowFullScreen
                />
              </div>
            </Col>
            <Col md={1} />
          </Row>

          <Modal
            onHide={this.hideModal}
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              {selectedImage && <Image src={selectedImage} />}
            </Modal.Body>
          </Modal>

          <div className="decoration-container">
            {/* <img
              src={require("./images/valentine/Valentine10.png")}
              loading="lazy"
              alt=""
            />

            <img
              src={require("./images/valentine/Valentine9.png")}
              loading="lazy"
              alt=""
            /> */}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default App;
