import React, { Component } from "react";
import {
  Row,
  Col,
  Nav,
  Carousel,
  Figure,
  Pagination,
  Button,
  Container
} from "react-bootstrap";
import { scrollToPoint } from "../tools/scroll";

class Products extends Component {
  state = {
    pageSelected: 1,
    galleryStatus: false,
    selectedOptionId: 6,
    data: [
      {
        id: 6,
        text: "Valentýn",
        subText:
          "Speciální nabídka pro Valentýn.",
        filesNumber: 10
      },
      {
        id: 0,
        text: "Novinky",
        subText:
          "Klenoty Zodiak Vám vždy nabízejí nejžhavější novinky, proto jsme pro Vás připravili kolekci nádherných motýlů a jedinečných šperků",
        filesNumber: 44
      },
      {
        id: 1,
        text: "Dětské náušnice",
        subText:
          "Klenoty Zodiak se specializují na zlaté dětské náušnice jak z bílého, tak žlutého zlata.",
        filesNumber: 188
      },
      {
        id: 2,
        text: "Dětské Přívěsky",
        subText:
          "Vybrali jste náušničky? doplňte je ještě přívěskem ve stejném stylu!",
        filesNumber: 16
      },
      {
        id: 3,
        text: "Snubní prsteny",
        subText:
          "Čeká Vás nejšťastnejší den v životě? Máme pro Vás ty nejkrásnější snubní prsteny. Klasický i moderní design pro nevěstu i ženicha v různých materiálech.",
        filesNumber: 34
      },
      {
        id: 4,
        text: "Ostatní šperky",
        subText:
          "Nabízíme šperky té nejvyšší kvality, samozřejmostí je certifikát a punc. U nás nehrozí potíže z některých e-shopů. Proto také prodáváme výhradně na prodejně, nikoliv pres internet.",
        filesNumber: 122
      },
      {
        id: 5,
        text: "Perly",
        subText:
          "Vaše perly jsou velkou cenností. Budete-li o ně vhodně pečovat, uchováte je i pro další generace. Šperky s perlami si připínejte vždy až po kosmetických úpravách a nanesení make-upu, po použití parfému a laku na vlasy. Tyto prostředky obsahují látky, které po čase zhoršují lesk perel. Perly můžete čistit vhodnou měkkou látkou.Uchovávejte perlové šperky odděleně od ostatních šperků a od ostrých tvrdých předmětů, které by mohly poškodit jejich povrch. Nenechávejte perly dlouhodobě v uzavřeném suchém prostoru, např. trezoru. Pravidelné nošení perel udržuje jejich lesk díky vlhkosti vzduchu a lidského těla.",
        filesNumber: 30
      }
    ]
  };

  constructor(props) {
    super(props);

    const counter = this.getTopNumber(1);
    const pagesNumber = Math.ceil(counter / 20);
    this.state.pagesNumber = pagesNumber;
    this.state.width = 0;
    this.state.height = 0;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  gallery = null;

  getImages = (count, skip, total, category) => {
    let arr = [];

    const finalNumber = skip + count > total ? total : skip + count;

    for (let i = skip + 1; i <= finalNumber; i++) {
      arr.push(
        <Col xs={12} md={4} lg={3} key={i}>
          <Figure>
            <Figure.Image
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.showModal(`/images/galerie/${category}/${i}.jpg`)
              }
              width={200}
              src={require(`../images/galerie/${category}/${i}.jpg`)}
              thumbnail
            />
          </Figure>
        </Col>
      );
    }

    return arr;
  };

  getArr = topNumber => {
    let arr = [];

    for (let i = 1; i <= topNumber; i++) {
      arr.push(i);
    }
    return arr;
  };

  getCarousselItems = () => {
    const ImagesNumber = Math.ceil(this.state.width / 200 - 3);
    const topNumber =
      this.getTopNumber(this.state.selectedOptionId) - ImagesNumber;   
    const loopArr = this.getArr(ImagesNumber);

    if (loopArr.length <= 0) return [];

    const arr = [];
    for (let i = 1; i <= topNumber; i) {
      arr.push(
        <Carousel.Item key={i} active={i === 1 ? "true" : "false"}>
          <div>
            {loopArr.map(a => (
              <img
               alt=""
                loading="lazy"
                style={{ height: "200px", margin: "3px" }}
                key={a}
                thumbnail
                src={require(`../images/galerie/${
                  this.state.selectedOptionId
                }/${i++}.jpg`)}
              />
            ))}
          </div>
        </Carousel.Item>
      );
    }
    return arr;
  };

  navClick = id => {
    const counter = this.getTopNumber(id);
    const pagesNumber = Math.ceil(counter / 20);
    this.setState({
      selectedOptionId: id,
      pagesNumber: pagesNumber,
      pageSelected: 1
    });
  };

  toggleGallery = () => {
    const status = !this.state.galleryStatus;
    this.setState({ galleryStatus: status });
  };

  getTopNumber = id => this.state.data.filter(d => d.id === id)[0].filesNumber;

  selectPage = number => {
    this.setState({ pageSelected: number.target.id });
    scrollToPoint(this.gallery);
  };

  getPagination = () => {
    var pagination = [];
    for (var i = 1; i <= this.state.pagesNumber; i++) {
      pagination.push(
        <Pagination.Item
          key={i}
          size="sm"
          active={this.state.pageSelected === i}
          id={i}
          onClick={this.selectPage}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pagination;
  };

  render({ data, selectedOptionId, galleryStatus, pageSelected } = this.state) {
    return (
      <React.Fragment>
        <Row className="rowWithPadding">
          <Col>
            <h2>Naše Nabídka</h2>

            <Nav className="justify-content-center" style={{ padding: "0px" }}>
              {data.map(n => (
                <Nav.Link
                  key={n.id}
                  href={`#${n.text}`}
                  onClick={() => this.navClick(n.id)}
                >
                  {n.text}
                </Nav.Link>
              ))}
            </Nav>

            <p>{data.filter(d => d.id === selectedOptionId)[0].subText}</p>
          </Col>
        </Row>

        {this.getTopNumber(selectedOptionId) > 0 && (
          <React.Fragment>
            <Row>
              <Col>
                <div className="sectionWrapper">
                  <Carousel
                    indicators={false}
                    wrap={true}
                    controls={true}
                    interval="20000"
                  >
                    {this.getCarousselItems()}
                  </Carousel>
                </div>
              </Col>
            </Row>

            <Row className="rowWithPadding">
              <Col>
                <Button onClick={() => this.toggleGallery()}>
                  {galleryStatus ? "Zavřít" : "Galerie"}
                </Button>
              </Col>
            </Row>

            {galleryStatus && (
              <React.Fragment>
                <Row
                  className="rowWithPadding"
                  ref={ref => (this.gallery = ref)}
                >
                  <Col></Col>
                  <Col xs={10}>
                    <Container fluid>
                      <Row>
                        {this.getImages(
                          20,
                          pageSelected * 20 - 20,
                          this.getTopNumber(selectedOptionId),
                          selectedOptionId
                        )}
                      </Row>
                    </Container>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="rowWithPadding">
                  <Col></Col>
                  <Col>
                    <Pagination className="justify-content-center">
                      {this.getPagination()}
                    </Pagination>
                  </Col>
                  <Col></Col>
                </Row>
                <Row className="rowWithPadding">
                  <Col>
                    <Button onClick={() => this.toggleGallery()}>
                      {galleryStatus ? "Zavřít" : "Galerie"}
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Products;


