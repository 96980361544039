import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

const Navigation = () => {
  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        style={{ backgroundColor: "rgba(83, 0, 0, 0.5)" }}
      >
        <Navbar.Brand href="#home">
          <img
            loading="lazy"
            alt="Zodiak"
            style={{
              height: "45px",
              margin: "0 15px 0 0px"
            }}
            src={require("../images/logo_white.png")}
          />
          <strong
            style={{
              color: "#fff",
              fontVariant: "small-caps",
              fontSize: "30px"
            }}
          >
            Zodiak
          </strong>
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#nabidka">Nabídka</Nav.Link>
            <Nav.Link href="#cisteni">Čištění stříbra</Nav.Link>
            <Nav.Link href="#opravy">Opravy</Nav.Link>
            <Nav.Link href="#zakazky">Zakázková výroba</Nav.Link>
            <Nav.Link href="#kontakty">Kontakty</Nav.Link>
            <Nav.Link
              href="https://www.facebook.com/klenotyZodiak/"
              target="blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default Navigation;
